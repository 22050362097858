import gsap from 'gsap'
import Cookies from 'js-cookie'
import store from 'store2'

class HeaderNotice {

  constructor(element) {
    this.element = element

    // this.closeButton = this.element.querySelector('[aria-expanded]')

    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  handleCloseClick() {
    this.hide()
  }

  hide() {
    this.element.classList.add('u-display-none');
    // this.closeButton.setAttribute('aria-expanded', 'false');
    document.documentElement.style.setProperty('--header-notice-height', '0px')
    Cookies.set('header_noticed_closed', 1);
  }

  handleResize() {
    this.setupDimensions()
  }

  setupDimensions() {
    const bannerRect = this.element.getBoundingClientRect()
    document.documentElement.style.setProperty('--header-notice-height', `${bannerRect.height}px`)

    setTimeout(() => {
      document.documentElement.classList.add('header-notice--ready')
    })
  }

  init() {

    if (!this.element) {
      return;
    }

    // this.closeButton.addEventListener('click', this.handleCloseClick)

    if (!Cookies.get('header_noticed_closed')) {
      this.element.classList.remove('u-display-none')
      document.body.classList.add('has-notice')
    }

    window.addEventListener('resize', this.handleResize)
    this.setupDimensions()
  }
}

export default HeaderNotice;

import Accordion from './accordion';

let accordions = [];

export const initAccordion = () => {
  accordions.forEach((accordion) => {
    accordion.unload();
  })


  accordions = [...document.querySelectorAll('.accordion')].map((element) => {
    const accordion = new Accordion(element);
    accordion.init();
    return accordion;
  })
}

// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// module.exports = (function(window, document, undefined) {
//   'use strict';
//   // Show More
//   var accordionContainer = document.querySelector('.js-accordion__container');

//   if (!accordionContainer) {
//     return;
//   }
//     var showAccordionBtn = document.querySelector('.js-accordion__button')

//     function loadMore() {

//         if(accordionContainer.classList.contains('open')){
//           accordionContainer.classList.remove('open')
//           document.body.classList.remove('u-overflow-hidden');
//         } else {
//           accordionContainer.classList.add('open')
//           document.body.classList.add('u-overflow-hidden');
//         }



//     }

//     showAccordionBtn.addEventListener('click', function(e) {
//       console.log('load more')
//       e.preventDefault();
//       loadMore()
//     });

// })(window, document);

// import crossfilter from 'crossfilter2';

// const recipes = crossfilter(recipesData)

// const recipeAttributeDimension = recipes.dimension((recipe) => {
//   return Object.keys(recipe.attributes).map((attributeKey) => {
//     return recipe.attributes[attributeKey].map((attribute) => {
//       return attribute.id
//     })
//   })
// }, true)

function serializeAttributes(selectedAttributes) {
  console.log(selectedAttributes);
}

function updateRecipes(selectedAttributes) {

  console.log(serializeAttributes(selectedAttributes));

  // $.get('/foodservice/recipes/search/?' + serializeAttributes(selectedAttributes))
  //   .then(function(response) {
  //     console.log(response);
  //   })



  console.log('selected recipes', selectedRecipes)
}

Array.from(document.querySelectorAll('input[type="checkbox"]')).forEach((element) => {
  element.addEventListener('change', (e) => {

    const selected = Array.from(document.querySelectorAll('input[type="checkbox"]:checked')).map((checkbox) => {
      return checkbox.value
    })

    updateRecipes(selected)
  })
})

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger)

class Waterfall {
  constructor(items) {
    this.items = Array.from(items).sort((a, b) => {
      return parseInt(a.dataset.animateOrder) - parseInt(b.dataset.animateOrder)
    })
  }

  init() {

    gsap.fromTo(this.items, {
        autoAlpha: 0,
        y: 200,
      }, {
      scrollTrigger: {
        trigger: this.items,
        start: "top 90%",
      },
      autoAlpha: 1,
      y: 0,
      stagger: .22,
      duration: 2,
      ease: "expo.out",
    });
  }
}

export default Waterfall;

import FadeIn from './fade-in'
import Waterfall from './waterfall';
import Stagger from './stagger';

export const initFadeIn = function() {
  new FadeIn(document.querySelectorAll('.js-fade'));

  // (new Waterfall(document.querySelectorAll('[data-animate="waterfall"]'))).init();
  // (new Stagger(document.querySelectorAll('[data-animate="stagger"]'))).init();
}

import { gsap } from "gsap";
import CSSPlugin from "gsap/CSSPlugin";

gsap.registerPlugin(CSSPlugin)

import selfOrClosest from '../libs/self-or-closest';

class Accordion {

  constructor(element) {
    this.element = element;
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleButtonMouseEnter = this.handleButtonMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.mouseEnterTimeout = null;
  }

  handleButtonClick(event) {
    const button = selfOrClosest(event.target, '[aria-controls]')
    const activeButton = this.element.querySelector('.active [aria-controls]')

    if ((button.closest('.accordion-item.active') || button.classList.contains('active')) && button.nodeName === 'A') {
      return;
    }

    event.preventDefault()

    if (button == activeButton) {
      this.toggleItemState(activeButton)
      return;
    }

    if (activeButton) {
      this.toggleItemState(activeButton)
    }

    this.toggleItemState(button)
  }

  handleButtonMouseEnter(event) {
    clearTimeout(this.mouseEnterTimeout)
    this.mouseEnterTimeout = setTimeout(() => {
      if (window.matchMedia('(hover: none)').matches) {
        return;
      }

      const button = selfOrClosest(event.target, '[aria-controls]');

      const activeButton = this.element.querySelector('.active [aria-controls]')
      if (activeButton == button) {

        return;
      }

      this.toggleItemState(button)
    }, 250)
  }

  handleMouseLeave(event) {

    if (window.matchMedia('(hover: none)').matches) {
      return;
    }

    const container = selfOrClosest(event.target, '.accordion-item');
    const button = container.querySelector('[aria-controls]')
    this.toggleItemState(button)
  }

  toggleItemState(button) {

    const contentId = button.getAttribute('aria-controls')
    const itemWrapper = button.closest('.accordion-item')
    const contentElement = itemWrapper.querySelector(`#${contentId}`)

    if (itemWrapper.classList.contains('active')) {
      itemWrapper.classList.remove('active')
      gsap.to(contentElement, {
        maxHeight: `${0}px`,
        duration: .5,
        ease: "power4.inOut",
      })

      return;
    }

    itemWrapper.classList.add('active')
    const itemContent = contentElement.querySelector('.accordion-item__content')
    const itemContentRect = itemContent.getBoundingClientRect()

    gsap.fromTo(contentElement,
      { maxHeight: `0px` },
      { maxHeight: `${itemContentRect.height}px`, duration: .5, ease: "power4.inOut", }
    )
  }

  closeAll() {
    [...this.element.querySelectorAll('.active')].forEach((active) => {
      active.classList.remove('active')

      const accordionItem = selfOrClosest(active, '.accordion-item')

      if (!accordionItem) {
        return;
      }

      const controlElement = accordionItem.querySelector('[aria-controls]')

      if (!controlElement) {
        return;
      }

      const contentId = controlElement.getAttribute('aria-controls')
      const contentElement = accordionItem.querySelector(`#${contentId}`)

      if (contentElement) {
        gsap.to(contentElement, { clearProps: true })
      }
    })
  }

  unload() {
    if (this.buttons) {
      this.buttons.forEach((button) => {
        button.removeEventListener('click', this.handleButtonClick)
        button.removeEventListener('mouseenter', this.handleButtonMouseEnter)
        button.removeEventListener('mouseleave', this.handleMouseLeave)
      })
    }
  }


  init() {

    this.buttons = this.element.querySelectorAll('[aria-controls]')

    this.buttons.forEach((button) => {
      button.removeEventListener('click', this.handleButtonClick)
      button.addEventListener('click', this.handleButtonClick)

      if (this.element.hasAttribute('data-accordion-hover')) {
        button.removeEventListener('mouseenter', this.handleButtonMouseEnter)
        button.addEventListener('mouseenter', this.handleButtonMouseEnter)
        const accordionItemWrapper = button.closest('.accordion-item')
        if (!accordionItemWrapper) {
          console.warn('Accordion button not contained within .accordion-item element')
          return;
        }

        accordionItemWrapper.addEventListener('mouseleave', this.handleMouseLeave)
      }
    })

    this.links = this.element.querySelectorAll('a')

    this.links.forEach((link) => {

      link.addEventListener("click", function (event) {
        var el = event.target

        if (el.tagName === "A" && !el.isContentEditable && el.host !== window.location.host) {
          el.setAttribute("target", "_blank")
        }
      }, true)

    })

    if (this.element.hasAttribute('data-accordion-active-item')) {
      const index = parseInt(this.element.dataset.accordionActiveItem) || 0;
      if (this.buttons[index]) {
        this.toggleItemState(this.buttons[index])
      }
    }
  }
}

export default Accordion;

// require('../libs/socialite.js');

function handlePrint(e) {
  e.preventDefault();
  window.print();
}

function handlePinterest(event) {
  event.preventDefault()

  const button = event.target.closest('button');
  const pinData = button.dataset

  try {
    window.PinUtils.pinOne({
      url: pinData.pinUrl || '',
      description: pinData.pinDescription || '',
      media: pinData.pinMedia || '',
    });
  } catch(e) {
    console.warn(e)
  }
}

export const initSocial = function() {
  // ------------------------------
  // Social Share Buttons
  // ------------------------------
  // // Everything else
  // Socialite.load();


  [...document.querySelectorAll('[data-pinterest-btn]')].forEach((elem) => {
    elem.removeEventListener('click', handlePinterest)
    elem.addEventListener('click', handlePinterest)
  });

  // ------------------------------
  // Print Buttons
  // ------------------------------

  [...document.querySelectorAll('.js-print')].forEach((elem) => {
    elem.removeEventListener('click', handlePrint)
    elem.addEventListener('click', handlePrint)
  })
}

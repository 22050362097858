import ActiveList from "./active-list"

let lists = [];

export const initActiveList = () => {

  lists.forEach((list) => {
    list.unload()
  })

  lists = [...document.querySelectorAll('.active-list')].map((element) => {
    let list = new ActiveList(element);
    list.init();
    return list;
  })
}

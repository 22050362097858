class Input {
  constructor(field) {
    this.field = field

    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)

    if (this.field) {
      this.field.addEventListener('focus', this.handleFocus)
      this.field.addEventListener('blur', this.handleBlur)
    }
  }

  handleFocus() {
    const pillContainer = this.field.closest('.c-form__pill')
    if (pillContainer) {
      pillContainer.classList.add('focus')
    }
  }

  handleBlur() {
    const pillContainer = this.field.closest('.c-form__pill')
    if (pillContainer) {
      pillContainer.classList.remove('focus')
    }
  }
}

export default Input;

import Navigation from './navigation';
import HeaderNotice from './header-notice';
import Search from './search';
import PageNav from './page-navigation';

class Header {
  constructor() {
    this.root = document.documentElement;
    this.container = document.querySelector('header')
    this.navigation = new Navigation(this.container)
    this.notice = new HeaderNotice(document.querySelector('.notice-banner'))
    this.noticeSentinal = document.querySelector('.notice-banner__sentinal')
    this.search = new Search(this.container)
    this.pageNav = new PageNav()
    this.sentinal = document.querySelector('.body__sentinal') || document.querySelector('.header__sentinal');

    this.headerLogo = this.container.querySelector('.header__logo')
    this.logoFull = this.container.querySelector('.logo-full')
    this.logoMedalion = this.container.querySelector('.logo-medalion')
    this.navigationFullSample = document.querySelector('.header__navigation-full')

    this.logoSpecs = {
      fullRect: null,
      medalionRect: null,
      leftOffset: 0,
      rightOffset: 0,
      leftFullOffset: 0,
      rightFullOffset: 0,
      navColumnGap: 0,
    }

    this.handleResize = this.handleResize.bind(this)
    this.handleNavigationOpen = this.handleNavigationOpen.bind(this)
    this.handleSearchOpen = this.handleSearchOpen.bind(this)
  }

  handleResize() {
    this.resize()
  }

  resize() {
    this.logoSpecs.fullRect = this.logoFull.getBoundingClientRect()
    this.logoSpecs.medalionRect = this.logoMedalion.getBoundingClientRect()

    this.logoSpecs.navColumnGap = parseFloat(window.getComputedStyle(this.navigationFullSample).getPropertyValue('column-gap')) / 2;

    // Calculate the width between the two logo. Used to determine how much to adjust the navigation spacing
    const splitOffset = Math.abs(this.logoSpecs.fullRect.width - this.logoSpecs.medalionRect.width) / 2
    this.logoSpecs.leftOffset = splitOffset
    this.logoSpecs.rightOffset = splitOffset * -1

    const splitFullOffset = (this.logoSpecs.fullRect.width / 2) + (this.logoSpecs.navColumnGap)
    this.logoSpecs.leftFullOffset = splitFullOffset
    this.logoSpecs.rightFullOffset = splitFullOffset * -1


    this.root.style.setProperty('--navigation-left-offset', `${this.logoSpecs.leftOffset}px`);
    this.root.style.setProperty('--navigation-right-offset', `${this.logoSpecs.rightOffset}px`);

    this.root.style.setProperty('--navigation-left-full-offset', `${this.logoSpecs.leftFullOffset}px`);
    this.root.style.setProperty('--navigation-right-full-offset', `${this.logoSpecs.rightFullOffset}px`);
    document.body.classList.add('navigation-ready')
  }

  updateNavigationState(state) {

    if (state === 'scrolled') {
      document.body.classList.add('body-scrolled')
      document.body.classList.add('has-scrolled')

      this.headerLogo.style.pointerEvents = 'none'
      setTimeout(() => {
        this.headerLogo.style.pointerEvents = 'auto'
      }, 1000)
      return;
    }

    document.body.classList.remove('body-scrolled')
    this.headerLogo.style.pointerEvents = 'none'
    setTimeout(() => {
      this.headerLogo.style.pointerEvents = 'auto'
    }, 1000)
  }

  handleNavigationOpen() {
    console.log('open')
    if (this.search.isOpen()) {
      this.search.close()
    }
  }

  handleSearchOpen() {
    if (this.navigation.isOpen()) {
      this.navigation.close()
    }
  }

  init() {
    this.navigation.init()
    this.search.init()
    this.pageNav.init()
    this.notice.init()

    var intersectionObserver = new IntersectionObserver(entries => {

      const noticeSentinal = entries.find((entry) => entry.target == this.noticeSentinal)

      if (noticeSentinal) {
        this.container.classList.toggle('header--fixed', noticeSentinal.intersectionRatio == 0)
      }

      if (entries.some(entry => entry.intersectionRatio == 0)) {
        this.updateNavigationState('scrolled')
        return;
      }

      this.updateNavigationState()

    });

    const sentinalElement = this.bodySentinal || this.sentinal
    if (sentinalElement) {
      intersectionObserver.observe(sentinalElement);
    }

    if (this.noticeSentinal) {
      intersectionObserver.observe(this.noticeSentinal);
    }

    this.resize()
    window.addEventListener('resize', this.handleResize)

    this.container.addEventListener('navigation_open', this.handleNavigationOpen)
    this.container.addEventListener('search_open', this.handleSearchOpen)
  }
}

export default Header;

const videoSelector = 'video[data-lazyload]';
const videoSourceAttribute = 'data-lazyload';
const hasLoadedClass = 'has-loaded';

/**
 * Add ready class when video loads.
 * @return {void}
 */
function addReadyClass() {
  this.hasLoaded = true;
  this.classList.add(hasLoadedClass);
}

/**
 * Fallback to image.
 * @see https://webkit.org/blog/6784/new-video-policies-for-ios/
 * @return {void}
 */
function fallback(e) {
  console.log(e)
  let img = this.querySelector('img');

  if (img) {
    this.parentNode.replaceChild(img, this);
  }
}

/**
 * Load video, fallback on error.
 * @param  {Element}  video
 * @return {Element}
 */
function load(video) {
  if (video.hasLoaded) { return; }

  let isVisible = window.getComputedStyle(video).display !== 'none';
  let src = video.getAttribute(videoSourceAttribute);

  video.addEventListener('error', fallback);

  if (isVisible && src) {
    video.src = src;

    // Force needed autoplay attributes
    if (video.hasAttribute('autoplay')) {
      video.muted = true
      video.autoplay = true
      video.playsinline = true
    }
  }

  requestAnimationFrame(function() {
    video.addEventListener('loadeddata', (event) => {
      event.target.hasLoaded = true
      event.target.classList.add(hasLoadedClass)
    });
  });

  return video;
}


/**
 * Load HTML5 videos.
 * @return {Array}
 */
function init() {
  return [...document.querySelectorAll(videoSelector)].map(load);
}

export const initVideoLazyLoad = function() {
  init();
  window.removeEventListener('resize', init);
  window.addEventListener('resize', init);
}


import * as Sentry from "@sentry/browser";
import {BrowserTracing} from "@sentry/tracing";

let env = 'production';

//handle enviroments
if (window.location.href.indexOf("localhost") > -1) {
  env = 'local';
} else if (window.location.href.indexOf("madebymode.dev") > -1) {
  env = 'autobuilder';
} else if (window.location.href.indexOf("staging") > -1) {
  env = 'staging';
} else if (window.location.href.indexOf("test") > -1) {
  env = 'test';
} else if (window.location.href.indexOf("dev") > -1) {
  env = 'dev';
}

Sentry.init({
  dsn: "https://a43e96a64f70414c90cb436db04c8c72@o504668.ingest.sentry.io/5724379",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  integrations: [new BrowserTracing()],
  environment: env,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Only errors created within scripts inside these domains will be captured (ie: we will not capture 3rd party code like pinterest or onetrust)
  allowUrls: [
    /https?:\/\/((fastly|www)\.)?boarshead\.com/
  ],


});

import Form from '../forms/form'
const loginModal = document.getElementById('foodservice-login-modal')

if (loginModal) {

  const loginFormContainer = loginModal.querySelector('.js-login-form-container')
  const resetFormContainer = loginModal.querySelector('.js-reset-form-container')

  const loginForm = new Form(loginFormContainer.querySelector('.c-form'), {
    contentType: 'application/json',
  })

  loginForm.successHandler = function(data) {
    // console.log('fired loginForm successHandler');
    // console.log(data);
    window.location.href = data.redirect
  }

  loginForm.errorHandler = function(data) {
    const errorMessage = loginFormContainer.querySelector('.js-main-error')
    // console.log('fired loginForm errorHandler');
    // console.log(data);
    errorMessage.textContent = data.message
  }

  const resetForm = new Form(resetFormContainer.querySelector('.c-form'), {
    contentType: 'application/json',
  })

  resetForm.successHandler = function(data) {
    // console.log('fired resetForm successHandler');
    // console.log(data);
    window.location.href = "/foodservice"
  }

  resetForm.errorHandler = function(data) {
    const errorMessage = resetFormContainer.querySelector('.js-main-error')
    // console.log('fired resetForm errorHandler');
    // console.log(data);
    errorMessage.textContent = data.message
  }

  loginFormContainer.querySelector('.js-forgot-password').addEventListener('click', function(e) {
    e.preventDefault();
    loginFormContainer.classList.add('u-display-none')
    resetFormContainer.classList.remove('u-display-none')
    // console.log('fired forgot password event listener')
  })

  resetFormContainer.querySelector('.js-cancel').addEventListener('click', function(e) {
    e.preventDefault();
    loginFormContainer.classList.remove('u-display-none')
    resetFormContainer.classList.add('u-display-none')
    // console.log('fired cancel event listener')
  })

  // Reset the modal state after it's been closed
  loginModal.addEventListener('modal.hide', function(e) {
    loginFormContainer.classList.remove('u-display-none')
    resetFormContainer.classList.add('u-display-none')

    Array.from(loginModal.querySelectorAll('.is-submitted')).forEach((element) => {
      element.classList.remove('is-submitted')
    })

    Array.from(loginModal.querySelectorAll('.is-active')).forEach((element) => {
      element.classList.remove('is-active')
    })
  })

}

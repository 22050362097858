import Modal from './modal'

import { initSitePromoModal } from './site-promo-modal'

export const initModals = function() {
  // ------------------------------
  // Modals
  // ------------------------------

  var modalElements = document.querySelectorAll('.js-modal-carousel__item'),
    modals = {}, i;

  // Store modals by ID
  for (i = 0; i < modalElements.length; i++) {
    if (modalElements[i].id) {
      modals['#' + modalElements[i].id] = Modal.create(modalElements[i]);
    }
  }

  // ------------------------------
  // Open Buttons
  // ------------------------------

  [...document.querySelectorAll('.js-open-modal')].map(function(button, index) {
    button.addEventListener('click', function(e) {
      let hash = "";
      e.preventDefault();
      if (this.tagName === "A" && this.hash) {
        hash = this.hash;
      } else if (this.tagName === "BUTTON" && this.dataset.hash) {
        hash = this.dataset.hash;
      }
      if (!hash || !modals[hash]) {
        return false;
      }

      modals[hash].setReturnFocus(this);
      modals[hash].show();
    });
  });

  [...document.querySelectorAll('[data-modal]')].map(function(button, index) {

    (function(btn) {
      btn.addEventListener('click', (e) => {
        e.preventDefault();

        const hash = btn.dataset.modal
        if (!hash || !modals[hash]) {
          return false;
        }

        modals[hash].setReturnFocus(btn);
        modals[hash].show(btn.href);
      })
    })(button)
  });

  initSitePromoModal(modals)

  window.modals = modals
  window.dispatchEvent(new CustomEvent('modal_init'))
  return modals;
}

export const getModals = function() {
  if (typeof window.modals !== 'undefined') {
    return Promise.resolve(window.modals)
  }

  return new Promise((resolve) => {
    window.addEventListener('modal_init', () => {
      resolve(window.modals)
    })
  })
}

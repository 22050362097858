module.exports = function(callback, timeout) {

  var dbTimeout = null;

  return function() {

    clearTimeout(dbTimeout);

    dbTimeout = setTimeout(() => {

      if (typeof callback === 'function') {
        callback.apply(this, Array.prototype.slice.call(arguments, 0));
      }

    }, timeout);
  };

};

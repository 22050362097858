import Dropdown from './dropdown'

let dropdownElements = [];

function init () {

  if (dropdownElements.length) {
    dropdownElements.forEach((dropdownElement) => {
      dropdownElement.dropdown.destroy()
    })
  }

  dropdownElements = [...document.querySelectorAll('.js-toggle-dropdown')]

  dropdownElements.forEach((button) => {
    const dd = new Dropdown(button)
    dd.init()
  });
}

export const initDropdown = function() {
  init()
  document.addEventListener('PageContentLoaded', init)
}

import HeaderVideo from './header-video'

import { isVisible } from '../utils'

var throttle = require('mode-front-end/resources/assets/js/event/throttle');

export const initHeaderVideos = function() {
  // ------------------------------
  // Init
  // ------------------------------

  var headerVideos = [...document.querySelectorAll('.js-header-video')].map(function(el) {
    return new HeaderVideo(el);
  }), playedVideosCount = 0;

  var scrollHandler = throttle(function(event) {
    // If all videos have been played, remove listener
    if (playedVideosCount >= headerVideos.length) {
      window.removeEventListener('scroll', scrollHandler);
      return false;
    }

    // Play videos when sections become visible
    headerVideos.forEach(function(headerVideo) {
      if (headerVideo.hasScrolled) {
        return false;
      }

      if (isVisible(headerVideo.section)) {
        headerVideo.hasScrolled = true;
        headerVideo.playVideo();
      }
    });
  }, 100);

  // Only initialize if videos exist
  if (headerVideos.length > 0) {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
  }

  return headerVideos;
}

export const isVisible = (el, offset) => {
  var rect = el.getBoundingClientRect();
  var visibileOffset = -150; // -50;

  if (typeof offset !== 'undefined') {
    visibileOffset = offset
  }

  return (
    rect.top < (window.innerHeight + visibileOffset || document.documentElement.clientHeight + visibileOffset)
  );
}

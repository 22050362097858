export default class ShareService {

  constructor(container) {
    this.container = container
    this.trigger = this.container.querySelector('.share-service__trigger')

    this.handleTriggerClick = this.handleTriggerClick.bind(this)
    this.handleBodyClick = this.handleBodyClick.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }

  isOpen() {
    return this.container.classList.contains('active')
  }

  open() {
    this.container.classList.add('ready')
    setTimeout(() => {
      this.container.classList.add('active')
    })
  }

  close() {
    this.container.classList.remove('active')

    setTimeout(() => {
      this.container.classList.remove('ready')
    }, 250)
  }

  handleBodyClick(event) {
    if (event.target.matches('.share-service__trigger') ||
      event.target.closest('.share-service__trigger') ||
      event.target.closest('.share-service')) {
      return;
    }

    this.close()
  }

  handleTriggerClick(event) {
    event.preventDefault()
    if (this.isOpen()) {
      this.close()
      return;
    }

    this.open()
  }

  destroy() {

  }

  init() {

    if (this.trigger) {
      this.trigger.addEventListener('click', this.handleTriggerClick)
      document.body.addEventListener('click', this.handleBodyClick)
    }
  }

}

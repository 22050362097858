let fullCtas = [];

import FullCta from './full-cta';

export const initFullCta = () => {

  if (fullCtas) {
    fullCtas.forEach((cta) => {
      cta.unload()
    })
  }

  fullCtas = [...document.querySelectorAll('.full-cta')].map((element) => {
    const cta = new FullCta(element);
    cta.init()
    return cta;
  })
}

class PageNavigation {

  constructor() {
    this.sentinal = document.querySelector('.header__page-navigation-sentinal');
    this.container = document.querySelector('.header__page-navigation')

    this.handleResize = this.handleResize.bind(this)

    this.intersectionObserver = null
  }

  updateNavigationState(state) {
    document.body.classList.toggle('page-navigation--open', state === 'scrolled')
  }

  setupObserver() {

    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }

    const headerHeight = window.getComputedStyle(document.documentElement).getPropertyValue('--header-height');
    const sentinalRect = this.sentinal.getBoundingClientRect()

    this.intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.intersectionRatio == 0)) {
        this.updateNavigationState('scrolled')
        return;
      }

      this.updateNavigationState()
    }, {
      rootMargin: `-${headerHeight} 0px ${sentinalRect.bottom}px 0px`
    });

    this.intersectionObserver.observe(this.sentinal);
  }

  resize() {
    this.setupObserver()
  }

  handleResize() {
    this.resize()
  }

  init() {

    if (!this.container || !this.sentinal) {
      console.warn('container or sentinal is new set for the current page.')
      return;
    }

    this.resize()
    window.addEventListener('resize', this.handleResize)
  }
}

export default PageNavigation;

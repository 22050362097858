import VideoPlayer from './video-player'
import Modal from '../modal/modal'

// ------------------------------
// Init
// ------------------------------

// Create array of unique video players based on play button hashes
var _hash;
var _modalIndex = 0;
var _modalTemplate = document.querySelector('#video-modal-template');
if (_modalTemplate) {
  _modalTemplate = _modalTemplate.textContent.replace(/\\\//g, '/').trim();
} else {
  console.log('could not find #video-modal-template')
}

let openButtonsByHash = [];
let openButtons = [];
let videoPlayers = {};

export const initVideoPlayers = function() {

  if (openButtons.length) {
    openButtons.forEach((openButton) => {
      openButton.removeEventListener('click', videoPlayers[openButton.hash].openClickListener)
      openButton.removeEventListener('touchstart', videoPlayers[openButton.hash].openClickListener)
    })
  }

  openButtons = [...document.querySelectorAll('.js-play-video')];

  for (var i = 0; i < openButtons.length; i++) {
    _hash = openButtons[i].hash;
    /**
     * Video Player Modals
     *
     * If this button points to a video URL instead of a player ID,
     * then create on-the-fly video modals with the following markup:
     *
     * ```html
     * <a href="https://youtu.be/gExT8CZcnx8" class="u-small-caps  /  c-link--cta  /  js-play-video">Play Video</a>
     * ```
     *
     * You can optionally add GA event tracking:
     *
     * ```html
     * <a href="https://youtu.be/gExT8CZcnx8" class="u-small-caps  /  c-link--cta  /  js-play-video" data-ga-click-event='{ "eventCategory": "Home", "eventAction": "Clicked Featured Product (Play Video)", "eventLabel": "https://youtu.be/gExT8CZcnx8" }'>Play Video</a>
     * ```
     *
     * @see resources/patternlab/source/_patterns/02-organisms/02-modals/video-modal.mustache
     */
    if (!_hash && openButtons[i].href) {
      var newVideoModal = document.createElement('div');
      newVideoModal.innerHTML = _modalTemplate;
      newVideoModal = newVideoModal.firstElementChild;

      let h1 = newVideoModal.querySelector("h1");
      if (h1 && openButtons[i].dataset.modalh1) {
        h1.innerHTML = openButtons[i].dataset.modalh1;
      } else if (h1) {
        h1.remove();
      }

      var newVideoModalIndex = ++_modalIndex;
      newVideoModal.id = 'video-modal-' + newVideoModalIndex;

      var newVideoModalFigure = newVideoModal.querySelector('.o-video__figure');
      newVideoModalFigure.id = 'video-modal-' + newVideoModalIndex + '-figure';

      var newVideoModalData = JSON.parse(newVideoModalFigure.getAttribute('data-video'));
      newVideoModalData.src = openButtons[i].href;
      newVideoModalData.modal = '#' + newVideoModal.id;

      newVideoModalFigure.setAttribute('data-video', JSON.stringify(newVideoModalData));
      newVideoModalFigure.querySelector('[id$="iframe"]').id = newVideoModalFigure.id + '-iframe';
      _hash = '#' + newVideoModalFigure.id;

      document.body.appendChild(newVideoModal);
      window.modals['#' + newVideoModal.id] = Modal.create(newVideoModal);
      openButtons[i].href = '#' + newVideoModal.id;
    }

    // If hash is null or element doesn't exist, continue
    if (!_hash || !document.querySelector(_hash)) {
      continue;
    }

    // If player doesn't already exist, create a new one
    if (!videoPlayers.hasOwnProperty(_hash)) {
      console.log('creating new video player')
      videoPlayers[_hash] = new VideoPlayer(_hash, openButtonsByHash);
    }

    // Group buttons by hash
    openButtonsByHash[_hash] = openButtonsByHash[_hash] || []; // Create array if none exists
    openButtonsByHash[_hash].push(openButtons[i]);

    // Add open event to all buttons for videos in array
    openButtons[i].addEventListener('click', videoPlayers[_hash].openClickListener);
    openButtons[i].addEventListener('touchstart', videoPlayers[_hash].openClickListener);
  }

  window.videoPlayers = videoPlayers
  return videoPlayers;
}

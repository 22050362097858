
import Form from './form'
import Input from './input'


export const initForm = function() {
  [...document.querySelectorAll('.js-form-validate')].map(form => new Form(form));

  [...document.querySelectorAll('input')].map(input => new Input(input));
}

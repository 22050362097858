'use strict';

class Modal {
  constructor(element) {

    this.element = element
    this.contentElement = element.querySelector('.c-modal__content')

    this.uri = element.dataset.uri
    this.returnUri = element.dataset.returnUri || window.location.pathname

    this.clickHandler = this.clickHandler.bind(this)
    this.backdropClickHandler = this.backdropClickHandler.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.escapeHandler = this.escapeHandler.bind(this);
    this.dismissHandler = this.dismissHandler.bind(this);
    this.captureFocusHandler = this.captureFocusHandler.bind(this);
    this.popstateHandler = this.popstateHandler.bind(this)
    this.onHide = null;
  }

  clickHandler() {
    /**
     * TODO: Determine whether we actually need to stopPropagation here.
     * If so, we'll need a workaround for handling `document` event listeners
     * within modals (e.g., custom `select`s used in modal forms).
     * For example, see the modal form on the Foodservice page.
     */
    // e.stopPropagation();
  }

  backdropClickHandler(e) {
    if (e.target != e.currentTarget) {
      return;
    }
    this.hide();
  }

  resizeHandler(e) {
    if (this.contentElement) {
      var self = this;
      var clientRect = self.contentElement.getBoundingClientRect();
      if (window.innerHeight < clientRect.height + 200) {
        self.element.classList.add('c-modal--align-top');
      } else if (window.innerHeight > clientRect.height + 200) {
        self.element.classList.remove('c-modal--align-top');
      }
    }
  }

  escapeHandler(e) {
    if (e.key == 'Escape') {
      this.hide();
    }
  }

  dismissHandler(e) {
    e.preventDefault();
    this.hide();
  }

  captureFocusHandler(e) {
    let feles;
    if (e.key == 'Tab') {
      feles = this.element.querySelectorAll("a, input, select, button");
      if (feles) {
        const firstEle = feles[0];
        const lastEle = feles[(feles.length-1)];
        if (e.target == firstEle && e.shiftKey) {
          e.preventDefault();
          lastEle.focus();
        } else if (e.target == lastEle && !e.shiftKey) {
          e.preventDefault();
          firstEle.focus();
        }
      }
    }
  }

  show() {

    if (this.uri) {
      window.history.pushState({}, '', this.uri)
    }

    document.body.classList.add('modal-open');
    if (this.element.hasAttribute('data-with-navigation')) {
      document.body.classList.add('modal-open--with-nav');
    }

    this.element.classList.add('is-active');
    this.setFocus();
    this.element.addEventListener("keydown", self.captureFocusHandler);
    this.element.dispatchEvent(new CustomEvent('modal.show'));

    // bind event handlers once the stack is cleared.
    // prevents triggering these event handlers from bubbling click events
    // when it's initially opened.
    setTimeout(() => {

      this.element.scrollTop = 0;

      // ensure the modal is in the correct display state for the viewport
      this.resizeHandler();

      // handle when the backdrop is clicked
      if (!this.element.hasAttribute('disable-backdrop-dismiss')) {
        this.element.addEventListener('click', this.backdropClickHandler);
      }

      // handle when the modal is clicked. should prevent closing the modal
      this.element.addEventListener('click', this.clickHandler);

      window.addEventListener('resize', this.resizeHandler);
      if (!this.element.hasAttribute('disable-escape-dismiss')) {
        document.addEventListener('keydown', this.escapeHandler);
      }

      [...this.element.querySelectorAll('.js-modal-dismiss')].forEach((dismissTrigger) => {
        dismissTrigger.addEventListener('click', this.dismissHandler);
      });

      window.addEventListener('popstate', this.popstateHandler);

    });
  }

  hide() {

    if (this.uri) {
      window.history.pushState({}, '', this.returnUri)
    }

    if (this.onHide !== null && this.onHide.constructor.name === "Function") {
      this.onHide();
    }
    if (document.body.classList.contains('modal-open')) {
      this.element.dispatchEvent(new CustomEvent('modal.hide'));
    }
    document.body.classList.remove('modal-open');
    document.body.classList.remove('modal-open--with-nav');
    this.element.classList.remove('is-active');
    this.setFocus();
    this.element.removeEventListener("keydown", this.captureFocusHandler);
    this.element.removeEventListener('click', this.clickHandler);
    this.element.removeEventListener('click', this.backdropClickHandler);
    window.removeEventListener('resize', this.resizeHandler);
    document.removeEventListener('keydown', this.escapeHandler);
  }

  isActive() {
    return this.element.classList.contains('is-active');
  }

  setReturnFocus(ele) {
    if (typeof ele == "string") {
      ele = document.getElementById(ele);
    }
    if (typeof ele.focus == "function") {
      this.returnFocusElement = ele;
    }
  }

  setFocus() {
    let focusEle, style, inpts;
    if (this.element.classList.contains('is-active')) {
      inpts = this.contentElement.querySelectorAll("input[type=text],input[type=email]");
      if (inpts) {
        for (let i = 0; i < inpts.length; i++) {
          style = window.getComputedStyle(inpts[i]);
          if (style.display != 'none' && style.visibility != 'hidden') {
            focusEle = inpts[i];
            break;
          }
        }
      }
      if (!focusEle) {
        focusEle = this.contentElement.querySelector(".c-modal__close, .js-modal-dismiss");
      }
      if (focusEle) {
        focusEle.focus();
        focusEle.setAttribute("data-focus","focus");
      }
    } else {
      focusEle = this.contentElement.querySelector("[data-focus=focus]");
      if (focusEle) {
        focusEle.blur();
        focusEle.removeAttribute("data-focus");
        if (this.returnFocusElement) {
          // this.returnFocusElement.focus();
        }
      }
    }
  }

  popstateHandler(event) {
    if (this.uri && this.isActive()) {
      this.hide()
    }
  }

  static create(selector) {
    const modal = new Modal(selector && selector.nodeType === 1 ? selector : document.querySelector(selector))
    return modal
  }
}

export default Modal;

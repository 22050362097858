import selfOrClosest from '../libs/self-or-closest'

class Dropdown {
  constructor(container) {
    this.container = container
    this.placeholderValue = this.container.dataset.dropdownPlaceholder || '';
    this.name = this.container.dataset.dropdownName || ''
    this.button = this.container.querySelector('[data-dropdown-button]')
    this.buttonDeselect = this.container.querySelector('[data-dropdown-deselect-button]')
    this.lengthElement = this.container.querySelector('[data-dropdown-option-length]')
    this.selectedLabel = this.container.querySelector('[data-dropdown-selected-label]')
    this.content = this.container.querySelector('[data-dropdown-content]')
    this.groupElement = this.container.closest('.dropdown__group')

    this.noResultsElem = this.container.querySelector('.dropdown__option--no-results')

    this.widthMultiplier = 1.4;

    this.value = null;
    this.label = null;

    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleButtonFocus = this.handleButtonFocus.bind(this)
    this.handleBodyClick = this.handleBodyClick.bind(this)
    this.handleBodyKeyDown = this.handleBodyKeyDown.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleDeselectButtonClick = this.handleDeselectButtonClick.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleContentClosingTransitionEnd = this.handleContentClosingTransitionEnd.bind(this)
    this.updateContentHeight = this.updateContentHeight.bind(this)

    this.inputTimeout = null;
    this.optionValues = [];

    // Make the dropdown accessible from the dom
    this.container.dropdown = this
  }

  open() {

    // Close an other dropdowns that might be open
    [...document.querySelectorAll('.dropdown.open')].forEach((ddElem) => {
      ddElem.dropdown.close()
    })

    this.updateContentHeight()

    this.isOpen = true;
    this.isFocused = true;
    this.container.classList.remove('closing') // just in case
    this.container.classList.add('open')
    this.button.focus();
    this.button.setAttribute('aria-expanded', 'true')
    this.updateFocusedState()

    if (this.groupElement) {
      this.groupElement.classList.add('open')
      clearTimeout(this.groupTimeout)
    }
  }

  close() {
    this.isOpen = false;
    this.isFocused = false;
    this.container.classList.remove('open')
    this.container.classList.add('closing')
    this.content.addEventListener('transitionend', this.handleContentClosingTransitionEnd)

    this.container.removeEventListener('keydown', this.handleKeyDown)

    this.button.setAttribute('aria-expanded', 'false')

    // Return to focus to the button on close
    this.button.focus();

    if (this.groupElement) {
      this.groupTimeout = setTimeout(() => {
        this.groupElement.classList.remove('open')
      }, 500)
    }
  }

  handleContentClosingTransitionEnd(event) {
    if (this.container.classList.contains('closing')) {
      this.container.classList.remove('closing')
      this.content.removeEventListener('transitionend', this.handleContentClosingTransitionEnd)
    }
  }

  handleButtonClick(event) {
    event.stopPropagation()

    if (this.isOpen) {
      this.close()
      return;
    }

    // Prevent clicks opening the results when the field is searchable
    // The results will open up as the user types
    if (this.selectedLabel.tagName === 'INPUT') {
      return;
    }

    this.open()
  }

  handleButtonFocus(event) {
    this.isFocused = true;
    this.container.addEventListener('keydown', this.handleKeyDown)
  }

  updateFocusedState() {
    // Update the focused selected state
    this.optionBtns.forEach((option) => {
      if (option.dataset.optionSelected === 'true') {
        option.classList.add('focus')
        option.focus()

        const parentRect = option.parentNode.getBoundingClientRect()
        const ul = option.parentNode.closest('ul')
        ul.scrollTop = option.parentNode.offsetTop;

        return;
      }

      option.classList.remove('focus')
    })
  }

  findFocusedOptionIndex() {
    return this.optionBtns.findIndex((btn) => btn.classList.contains('focus'))
  }

  handleKeyDown(event) {

    if (event.key === 'ArrowDown') {
      event.preventDefault()

      if (!this.isOpen) {
        this.open()
        return;
      }

      const focusedIndex = this.findFocusedOptionIndex()
      const nextIndex = focusedIndex + 1

      if (nextIndex < this.optionBtns.length) {

        if (this.optionBtns[focusedIndex]) {
          this.optionBtns[focusedIndex].classList.remove('focus')
        }

        this.optionBtns[nextIndex].classList.add('focus')
        this.optionBtns[nextIndex].focus()
      }
      return;
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()

      const focusedIndex = this.findFocusedOptionIndex()
      const prevIndex = focusedIndex - 1

      if (prevIndex > -1) {

        if (this.optionBtns[focusedIndex]) {
          this.optionBtns[focusedIndex].classList.remove('focus')
        }

        this.optionBtns[prevIndex].classList.add('focus')
        this.optionBtns[prevIndex].focus()
      }
      return;
    }
  }

  handleBodyClick(event) {
    if (!this.isOpen || selfOrClosest(event.currentTarget, '.dropdown')) {
      return;
    }

    this.close()
  }

  handleBodyKeyDown(event) {
    if (event.key == 'Escape') {
      this.close();
    }
  }

  handleResize() {
    this.resize()
  }

  resize() {
    const optionLengthRect = this.lengthElement.getBoundingClientRect()
    this.container.style.setProperty('--dropdown-width', `${optionLengthRect.width * this.widthMultiplier}px`)


    // this.updateContentHeight()
  }

  updateContentHeight() {
    const visibleBtns = this.optionBtns.filter((btn) => !btn.parentNode.classList.contains('u-display-none'))

    this.optionButtonReference = this.container.querySelector('.dropdown__btn')
    this.optionButtonReferenceRect = this.optionButtonReference.getBoundingClientRect()

    const maxItems = Math.min(8, visibleBtns.length)

    console.log(visibleBtns.length, this.optionButtonReferenceRect)

    this.container.style.setProperty('--dropdown-content-height', `${maxItems * (this.optionButtonReferenceRect.height * .8)}px`)
  }

  handleOptionClick(event) {
    const optionBtn = selfOrClosest(event.target, '[data-option-value]')
    this.setSelectedValue(optionBtn.dataset.optionValue)
  }

  handleDeselectButtonClick() {
    this.clear(null)
  }

  updateOptions() {

    this.optionValues = []

    if (this.optionBtns && this.optionBtns.length) {
      this.optionBtns.forEach((optionBtn) => {
        optionBtn.removeEventListener('click', this.handleOptionClick)
      })
    }

    this.optionBtns = [...this.container.querySelectorAll('.dropdown__option-btn')]
    this.optionBtns.forEach((optionBtn, i) => {
      if (!optionBtn.dataset.optionLabel) {
        return;
      }

      this.optionValues.push({ label: optionBtn.dataset.optionLabel, index: i })
      optionBtn.addEventListener('click', this.handleOptionClick)
    })
  }

  setInitialValue() {
    const selectedOption = this.container.querySelector('[data-option-selected="true"]')
    if (selectedOption) {
      this.setSelectedValue(selectedOption.dataset.optionValue, true)
    }
  }

  // Allow setting the selected based on value alone.
  // This makes it easier to allow updating the dropdown from anywhere
  setSelectedValue(value, disableChange) {

    // Assume there's nothing to do since the value is already the same
    if (this.value == value) {
      return;
    }

    this.value = value;

    // Reset the display when a falsy value is provided
    if (!this.value) {
      this.container.classList.remove('has-selection')
      this.updateSelectedLabel(this.placeholderValue, null, true)

      this.selectedIndex = null;
      this.label = null
    }

    this.optionBtns.forEach((optionBtn, i) => {
      if (this.value && optionBtn.dataset.optionValue == this.value) {
        optionBtn.setAttribute('data-option-selected', 'true')
        optionBtn.classList.add('focus')
        this.updateSelectedLabel(optionBtn.dataset.optionLabel, optionBtn.dataset.optionWrappedLabel)
        this.selectedIndex = i
        this.label = optionBtn.dataset.optionLabel
        this.container.classList.add('has-selection')
        return;
      }

      optionBtn.setAttribute('data-option-selected', 'false')
      optionBtn.classList.remove('focus')
    })

    if (!disableChange) {
      this.container.dispatchEvent(new Event('change'))
    }
  }

  updateSelectedLabel(label, wrappedLabel, isPlaceholder) {
    if (this.selectedLabel.tagName === 'INPUT') {
      this.selectedLabel.value = isPlaceholder ? '' : label;
      return;
    }

    this.selectedLabel.innerHTML = wrappedLabel || label;
  }

  addEventListener(event, listener) {
    this.container.addEventListener(event, listener)
  }

  removeEventListener(event, listener) {
    this.container.removeEventListener(event, listener)
  }

  handleInput(event) {
    this.open()
    clearTimeout(this.inputTimeout)
    this.inputTimeout = setTimeout(() => {
      this.filterValues(event.target.value)
      this.updateContentHeight()
    }, 250)
  }

  filterValues(value) {

    const regex = new RegExp(`${value}`, 'i')

    const matches = this.optionValues
      .filter((option) => option.label.match(regex))
      .map((option) => option.index)

    let availableOptionCount = 0;

    this.optionBtns.forEach((button, i) => {
      if (matches.indexOf(i) === -1) {
        button.parentNode.classList.add('u-display-none')
        return;
      }

      availableOptionCount += 1;
      button.parentNode.classList.remove('u-display-none')
    })

    if (availableOptionCount == 0) {
      this.noResultsElem.classList.remove('u-display-none')
    }
  }

  clear() {
    this.setSelectedValue(null)
  }

  destroy() {
    this.buttonDeselect.removeEventListener('click', this.handleDeselectButtonClick)
    this.button.removeEventListener('focus', this.handleButtonFocus)
    this.button.removeEventListener('click', this.handleButtonClick)
    document.body.removeEventListener('keydown', this.handleBodyKeyDown);
    document.body.removeEventListener('click', this.handleBodyClick);
    window.removeEventListener('resize', this.handleResize)
  }

  init() {
    this.buttonDeselect.addEventListener('click', this.handleDeselectButtonClick)
    this.button.addEventListener('focus', this.handleButtonFocus)
    this.button.addEventListener('click', this.handleButtonClick)
    document.body.addEventListener('keydown', this.handleBodyKeyDown);
    document.body.addEventListener('click', this.handleBodyClick);
    window.addEventListener('resize', this.handleResize)

    this.selectedLabel.addEventListener('input', this.handleInput)

    this.resize();

    this.updateOptions()
    this.setInitialValue()
  }
}

export default Dropdown

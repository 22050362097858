import './gascrolldepth.custom';

/**
 * HACK: Check whether the sticky nav is active.
 * @return {Boolean}
 */
var body = document.querySelector('body');
function hasStickyNav() {
  return body.classList.contains('has-sticky-nav');
}

class TrackEvent {

  constructor(params) {
    // Combine new params with event defaults
    this.event = {
      'event'               : 'GAEvent',
      'eventCategory'       : null,
      'eventAction'         : null,
      'eventLabel'          : null,
      'eventValue'          : 0,
      'eventNonInteraction' : false,
      ...params
    };

    this.fire = this.fire.bind(this)
  }

  /**
   * Helper for tracking Google Analytics events through Google Tag Manager.
   * @param  {Object}  params
   * @return {void}
   */
  fire(params) {
    let event = { ...this.event, ...params };

    // Note: Requiring all event fields will break Virtual Pageviews
    // if (!event.eventCategory ||
    //     !event.eventAction) {
    //   return false;
    // }

    // HACK: Set eventValue to 0 or 1 depending on whether the nav is in sticky mode
    if (event.eventCategory === 'Main Navigation' && hasStickyNav()) {
      event.eventValue = 1;
    }

    // Only trigger event if GTM has loaded
    if (typeof dataLayer !== 'undefined' && typeof dataLayer.push === 'function') {
      console.log('dataLayer.push', event)
      dataLayer.push(event);
    }
  };
}


export default class Analytics {

  constructor() {
    // Scroll %
    gascrolldepth.init({
      minHeight: 0,
      elements: [],
      percentage: true,
      percentageMarks: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      userTiming: false,
      pixelDepth: false,
      nonInteraction: true
      // DEBUG
      // eventHandler: function(data) {
      //   console.log(data);
      // }
    });

    this.getTrackEvent = this.getTrackEvent.bind(this)
    this.trackEvent = this.trackEvent.bind(this)
    this.getScrollDepth = this.getScrollDepth.bind(this)
    this.addScrollDepthElements = this.addScrollDepthElements.bind(this)
    this.clearScrollDepthElements = this.clearScrollDepthElements.bind(this)
    this.setScrollDepthElements = this.setScrollDepthElements.bind(this)
  }

  getTrackEvent(params) {
    const event =  new TrackEvent(params);
    return event.fire
  }

  trackEvent(params) {

    console.log('trackEvent Call', params)

    // Combine new params with event defaults
    let event = {
      'event'               : 'GAEvent',
      'eventCategory'       : null,
      'eventAction'         : null,
      'eventLabel'          : null,
      'eventValue'          : 0,
      'eventNonInteraction' : false,
      ...params
    };

    // Note: Requiring all event fields will break Virtual Pageviews
    // if (!event.eventCategory ||
    //     !event.eventAction) {
    //   return false;
    // }

    // HACK: Set eventValue to 0 or 1 depending on whether the nav is in sticky mode
    if (event.eventCategory === 'Main Navigation' && hasStickyNav()) {
      event.eventValue = 1;
    }

    // Only trigger event if GTM has loaded
    if (typeof dataLayer !== 'undefined' && typeof dataLayer.push === 'function') {
      dataLayer.push(event);
    }
  }

  getScrollDepth() {
    return gascrolldepth
  }

  addScrollDepthElements(elements) {
    gascrolldepth.addElements(elements)
  }

  clearScrollDepthElements() {
    gascrolldepth.clearElements()
  }

  setScrollDepthElements(elements) {
    gascrolldepth.clearElements()
    gascrolldepth.addElements(elements)
  }
}

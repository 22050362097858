import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import selfOrClosest from '../libs/self-or-closest'

class Navigation {

  constructor(container) {
    this.container = container

    this.burgerBtn = this.container.querySelector('.header__burger-btn')
    this.backBtn = this.container.querySelector('.header__navigation-back-btn')
    this.panelsContainer = this.container.querySelector('.header__navigation-panels')
    this.panelScrollContainer = this.container.querySelector('.header__navigation-panel-scroll')
    this.drawerNav = this.container.querySelector('.header__navigation-vertical')
    this.panels = [...this.panelsContainer.querySelectorAll('.header__navigation-panel')]
    this.navigationContent = this.container.querySelector('.header__content')

    const drawerControls = [...this.drawerNav.querySelectorAll('[aria-controls]')];
    const navigationControls = [...this.container.querySelectorAll('.navigation-item__primary')];
    this.panelLinks = navigationControls.concat(drawerControls);

    this.handlePanelLinkClick = this.handlePanelLinkClick.bind(this)
    this.handleBodyClick = this.handleBodyClick.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePanelCloseTransitionEnd = this.handlePanelCloseTransitionEnd.bind(this)
    this.handleBurgerClick = this.handleBurgerClick.bind(this)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleNavigationMouseEnter = this.handleNavigationMouseEnter.bind(this)
    this.handlePanelLinkMouseEnter = this.handlePanelLinkMouseEnter.bind(this)
    this.handlePanelLinkMouseLeave = this.handlePanelLinkMouseLeave.bind(this)
    this.handlePanelMouseEnter = this.handlePanelMouseEnter.bind(this)
    this.handlePanelMouseLeave = this.handlePanelMouseLeave.bind(this)

    this.desktopBp = window.matchMedia("(min-width: 1200px) and (min-height: 550px)");

    this.linkEnterTimeout = null
    this.navOpenTimeout = null
  }

  openPanel(panelId) {

    this.container.dispatchEvent(new CustomEvent('navigation_open'))

    // On Desktop, close the current panel when opening another
    if (this.desktopBp.matches && this.currentPanel && this.currentPanel.id != panelId) {
      this.closePanel()
    }

    if (!this.desktopBp.matches) {
      this.drawerNav.classList.add('disabled')
      this.panelsContainer.classList.add('selected')
    }

    const panel = document.getElementById(panelId)

    if (!this.desktopBp.matches) {
      // Allow scrolling of the current panel
      disableBodyScroll(this.panelScrollContainer)
    }

    if (!panel) {
      console.warn('panel id not found', panelId)
      return
    }

    // Reset the display style incase it was closed "immediately" using this.close()
    panel.style.display = ''

    const panelLinks = panel.getAttribute('aria-labelledby').split(' ').map((id) => document.getElementById(id))
    this.setAriaSelected(panelLinks, true)

    this.currentPanel = panel

    // Selected sets the display style for the panel
    panel.classList.add('selected')

    // Revel...reveals the panel. setTimeout here because requestAnimationFrame wasn't happening late enough
    // and was causing jumps because the display style wasn't fully rendered.
    setTimeout(() => {

      // Mobile Behavior
      if (!this.desktopBp.matches) {
        this.panelsContainer.classList.add('reveal')
      }

      // Desktop behavior
      if (this.desktopBp.matches) {
        panel.classList.add('reveal')
      }
    })
  }

  closePanel() {

    if (!this.currentPanel) {
      return;
    }

    this.container.dispatchEvent(new CustomEvent('navigation_close'))

    const panelLinks = this.currentPanel.getAttribute('aria-labelledby').split(' ').map((id) => document.getElementById(id))
    this.setAriaSelected(panelLinks, false)

    // On mobile, the full panels container is transitioned vs the individual panels
    if (!this.desktopBp.matches) {
      this.drawerNav.classList.remove('disabled')
      this.panelsContainer.addEventListener('transitionend', this.handlePanelCloseTransitionEnd)
      this.panelsContainer.classList.remove('reveal')
    }

    this.closingPanel = this.currentPanel

    // On desktop, transition the individual panels
    if (this.desktopBp.matches) {
      // this.closingPanel.addEventListener('transitionend', this.handlePanelCloseTransitionEnd)
      this.closingPanel.classList.remove('selected')
      this.closingPanel.classList.remove('reveal')
    }
  }

  handlePanelCloseTransitionEnd(event) {

    // Mobile behavior
    if (!this.desktopBp.matches) {
      this.panelsContainer.removeEventListener('transitionend', this.handlePanelCloseTransitionEnd)
      this.panelsContainer.classList.remove('selected')

      // Rest the current panel on mobile
      // There's only a single visible panel at a time
      this.currentPanel = null
    }

    if (this.closingPanel) {
      this.closingPanel.removeEventListener('transitionend', this.handlePanelCloseTransitionEnd)
      this.closingPanel.classList.remove('selected')
      this.closingPanel = null
    }
  }

  handleBodyClick(event) {

    const panelLink = selfOrClosest(event.target, '[aria-controls]')
    const drawer = selfOrClosest(event.target, '.header__navigation-drawer')

    if (panelLink || drawer) {
      return;
    }

    this.close()
  }

  handleKeyPress(event) {
    if (event.key.toLowerCase() == 'escape') {
      this.close()
    }
  }

  handleBurgerClick(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.isOpen()) {
      this.close()
      return
    }

    this.open()
  }

  handleResize() {
    this.eventHandlers()
    this.close(true)
  }

  isOpen() {
    return document.body.classList.contains('navigation-open')
  }

  open(panelId) {

    document.body.classList.remove('navigation-over')
    document.body.classList.add('navigation-open')

    if (!this.desktopBp.matches) {
      // Allow scrolling of the top level navigation
      disableBodyScroll(this.drawerNav)
    }

    this.container.dispatchEvent(new CustomEvent('navigation_open'))

    if (typeof panelId !== 'undefined') {
      this.openPanel(panelId)
    }
  }

  close(immediate) {

    document.body.classList.remove('navigation-open')
    document.body.classList.remove('navigation-over')

    clearAllBodyScrollLocks();

    // Force immediate cleanup. Mostly used for making
    // resizing the browser look less janky
    if (immediate === true) {

      this.panelsContainer.classList.remove('selected')
      this.panelsContainer.classList.remove('reveal')
      this.drawerNav.classList.remove('disabled')

      if (this.currentPanel) {
        this.currentPanel.style.display = 'none'
        this.currentPanel.classList.remove('selected')
        this.currentPanel.classList.remove('reveal')
      }

      this.closingPanel = null
      this.currentPanel = null

      this.setAriaSelected([...this.container.querySelectorAll('[aria-selected=true]')], false)

      return
    }

    this.closePanel()
  }

  setAriaSelected(tabs, selected) {
    tabs.forEach((tab) => {
      tab.setAttribute('aria-selected', selected ? 'true' : 'false')
    })
  }

  handleBackButtonClick(event) {
    event.preventDefault()
    event.stopPropagation()
    this.closePanel()
  }

  handlePanelLinkClick(event) {
    const panelLink = selfOrClosest(event.target, '[aria-controls]')
    const panelId = panelLink.getAttribute('aria-controls')

    event.preventDefault()

    // // panel is already open visit the link
    // if (panelLink.getAttribute('aria-selected') === 'true') {
    //   this.close()
    //   return;
    // }

    this.open(panelId)
  }

  handlePanelLinkDoubleClick(event) {
    window.location.href = event.target.getAttribute('href')
  }

  handlePanelLinkMouseEnter(event) {

    clearTimeout(this.navOpenTimeout)
    clearTimeout(this.linkEnterTimeout)

    const panelLink = selfOrClosest(event.target, '[aria-controls]')

    this.panelLinks.forEach((link) => link.classList.remove('selected'))

    // Navigation items without a panel are treated differently.
    // Closes the nav and "transitions" to the "navigation-over" state
    if (!panelLink) {
      this.close()
      document.body.classList.add('navigation-over')
      event.target.classList.add('selected')
    }

    this.linkEnterTimeout = setTimeout(() => {
      if (!panelLink) {
        return;
      }
      const panelId = panelLink.getAttribute('aria-controls')

      this.open(panelId)
    }, 250)
  }

  handleNavigationMouseEnter() {
    clearTimeout(this.navOpenTimeout)
    clearTimeout(this.linkEnterTimeout)
  }

  handlePanelLinkMouseLeave(event) {

    if (!event.target.hasAttribute('aria-controls')) {
      clearTimeout(this.navOpenTimeout)
      this.navOpenTimeout = setTimeout(() => {
        this.close()
      }, 250)
    }
  }

  handlePanelMouseLeave() {
    clearTimeout(this.navOpenTimeout)
    clearTimeout(this.linkEnterTimeout)
  }

  handlePanelMouseEnter(event) {
    clearTimeout(this.navOpenTimeout)
    // this.navOpenTimeout = setTimeout(() => {
    //   this.close()
    // }, 250)
  }

  handlePanelMouseLeave() {
    clearTimeout(this.navOpenTimeout)
    this.navOpenTimeout = setTimeout(() => {
      this.close()
    }, 250)
  }

  eventHandlers() {

    this.navigationContent.removeEventListener('mouseenter', this.handleNavigationMouseEnter)
    this.navigationContent.addEventListener('mouseenter', this.handleNavigationMouseEnter)

    this.panelLinks.forEach((panelLink) => {
      panelLink.removeEventListener('click', this.handlePanelLinkClick)
      panelLink.addEventListener('click', this.handlePanelLinkClick)
      panelLink.removeEventListener('dblclick', this.handlePanelLinkDoubleClick)

      panelLink.removeEventListener('mouseenter', this.handlePanelLinkMouseEnter)
      panelLink.removeEventListener('mouseleave', this.handlePanelLinkMouseLeave)

      if (this.desktopBp.matches) {
        panelLink.addEventListener('mouseenter', this.handlePanelLinkMouseEnter)
        panelLink.addEventListener('mouseleave', this.handlePanelLinkMouseLeave)

        panelLink.addEventListener('dblclick', this.handlePanelLinkDoubleClick)
      }
    })

    this.panels.forEach((panel) => {
      panel.removeEventListener('mouseenter', this.handlePanelMouseEnter);
      panel.removeEventListener('mouseleave', this.handlePanelMouseLeave);

      if (this.desktopBp.matches) {
        panel.addEventListener('mouseenter', this.handlePanelMouseEnter);
        panel.addEventListener('mouseleave', this.handlePanelMouseLeave);
      }
    })
  }

  init() {

    this.eventHandlers()

    document.body.addEventListener('click', this.handleBodyClick)
    document.body.addEventListener('keyup', this.handleKeyPress)

    this.burgerBtn.addEventListener('click', this.handleBurgerClick)
    this.backBtn.addEventListener('click', this.handleBackButtonClick)

    window.addEventListener('resize', this.handleResize);
  }

}

export default Navigation;

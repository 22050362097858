class Tabs {
  constructor(element) {

    this.element = element
    this.tabs = [...this.element.querySelectorAll('[role="tab"]')]
    this.tabSelect = this.element.querySelector('select')
    this.tabPanels = [...this.element.querySelectorAll('[role="tabpanel"]')]
    this.featuredProjects = {}

    this.currentTab = null;
    this.currentTabPanel = null;

    this.handlePanelTransitionEnd = this.handlePanelTransitionEnd.bind(this)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  handleTabClick(event) {
    event.preventDefault();

    const tab = event.target.closest('[role="tab"]')
    this.setSelectedTab(tab)
  }

  setSelectedTab(tab) {

    const controlledTabPanelId = tab.getAttribute('aria-controls')
    const controlledTabPanel = this.tabPanels.find((tabPanel) => tabPanel.id == controlledTabPanelId)

    if (!controlledTabPanel) {
      return;
    }

    // Prep the new tab panel to be displayed
    controlledTabPanel.classList.add('ready')

    requestAnimationFrame(() => {

      // Transition out the current tab panel
      ((currentTab, currentTabPanel) => {
        if (currentTab) {
          currentTab.setAttribute('aria-selected', 'false')
        }

        if (currentTabPanel) {
          // After animation is complete, remove the ready class
          currentTabPanel.addEventListener('transitionend', this.handlePanelTransitionEnd)
          currentTabPanel.classList.remove('selected')
        }

      })(this.currentTab, this.currentTabPanel);

      // transition to the new tab
      tab.setAttribute('aria-selected', 'true')
      controlledTabPanel.classList.add('selected')

      // Reset the scroll position of the parent whenever a new tab is displayed
      if (controlledTabPanel.parentNode) {
        controlledTabPanel.parentNode.scrollTop = 0
      }

      this.currentTab = tab
      this.currentTabPanel = controlledTabPanel
    })
  }

  handlePanelTransitionEnd(event) {
    if (event.target.classList.contains('ready')) {
      event.target.classList.remove('ready')
      event.target.removeEventListener('transitionend', this.handlePanelTransitionEnd)
    }
  }

  handleSelectChange(event) {
    const tab = this.tabs[event.target.selectedIndex]
    this.setSelectedTab(tab)
  }

  updateSelected() {
    this.currentTab = this.element.querySelector('[role="tab"][aria-selected="true"]')
    this.currentTabPanel = this.element.querySelector('[role=tabpanel].selected')
  }

  init() {
    this.tabs.forEach((tab) => {
      tab.addEventListener('click', this.handleTabClick);
    })

    if (this.tabSelect) {
      this.tabSelect.addEventListener('change', this.handleSelectChange)
    }

    this.updateSelected()
  }
}

export default Tabs;


import svg4everybody from 'svg4everybody'
svg4everybody()

import 'core-js/fn/array/from'
import 'core-js/fn/object/assign'

// Ideally remove this.
if (navigator.appVersion.indexOf("Win") != -1) {
  document.documentElement.classList.add('microsoft')
}

// Error tracing
import './sentry';

// Responsive images + Lazy loading
document.createElement('picture');
import 'picturefill'

// `object-fit` polyfill
// https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
import 'lazysizes'

import dispatchPageContentLoaded from './events/page-content-loaded'

// Do we want this on ALL pages?
import LocomotiveScroll from 'locomotive-scroll';



// const throttle = require('mode-front-end/resources/assets/js/event/throttle');

import './ada-jump-links'


if (typeof APP_CONFIG === 'undefined') {
  var APP_CONFIG = {};
}

import { initHeader } from './header';


import { initFullCta } from './full-cta';
import { initActiveList } from './active-list';
import { initAnalytics } from './analytics'
// import { initCursor } from './cursor';
import { initSmoothScroll } from './smooth-scroll';
import { initFadeIn } from './animations'
import { initDropdown } from './dropdown'
import { initDishworthyModal } from './forms/dishworthy'
import { initForm } from './forms'
import { initHeaderVideos } from './header-video'
import { initModals } from './modal'
import { initShowMore } from './show-more'
import { initSocial } from './social'
import { initTabs } from './tabs'
import { initVideoLazyLoad } from './video-lazyload'
import { initVideoPlayers } from './video-player'
import { initPdfEmbed } from './pdf-embed'
// import { initNotice } from './notice'
import { initToggles } from './toggle'
import { initShareServices } from './share-services';
import { initAccordion } from './accordion';

// DELETE IDEALLY

// // Browser Detects
// // const ie = require('mode-front-end/resources/assets/js/browserDetect/internetExplorer');
// // const ios = require('mode-front-end/resources/assets/js/browserDetect/ios');
// const fontFeatures = require('mode-front-end/resources/assets/js/browserDetect/fontFeatures');

// import { initShareMenu } from './share-menu';
// import { initSelectWidth } from './select-width/select-width'
// import { initScrollableGrid } from './scrollable-grid'
// import { initZipCode } from './forms/zipCode'
// import { setScrollBarSize } from './libs/scroll-bar-size';

// setScrollBarSize();


const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  // smooth: true
});

window.scroll = scroll

window.addEventListener('PageContentLoaded', () => {

  initAnalytics({ addDefaultClickHandlers: APP_CONFIG.overrideAnalytics ? false : true })
  initHeader();
  initFullCta();
  initActiveList();
  // initCursor();
  initFadeIn()
  initDropdown()
  initForm()

  initHeaderVideos()
  initModals()
  initDishworthyModal()
  initShowMore()
  initSocial()
  initTabs()
  initVideoLazyLoad()
  initVideoPlayers()
  initPdfEmbed()
  // initNotice()
  initToggles()
  initShareServices()
  initAccordion()


  // // STUFF TO DELETE...IDEALLY
  // initParallax();
  // initSelectWidth()
  // initShareMenu()
  // initScrollableGrid()
  // initZipCode()


  // smoothScroll = initSmoothScroll()

  // if (typeof window.updateSmoothScrollOffset === 'undefined') {
  //   function updateSmoothScrollOffset() {
  //     // Get lowest bounding rect (since addOn height may actually be taller than visible area)
  //     // Use timeout to avoid getting incorrect positions
  //     setTimeout(function() {
  //       var height = header.el().getBoundingClientRect().height;
  //       // var offset = header.addOn().getBoundingClientRect().height;
  //       smoothScroll.setOffset(height + offset);
  //     }, 100);
  //   }

  //   window.updateSmoothScrollOffset = updateSmoothScrollOffset;
  //   window.throttledUpdateSmoothScrollOffset = throttle(updateSmoothScrollOffset, 100)
  // }

  // if (header.addOn()) {
  //   updateSmoothScrollOffset()
  //   window.removeEventListener('headerAddOnChanged', updateSmoothScrollOffset, false);
  //   window.addEventListener('headerAddOnChanged', updateSmoothScrollOffset, false);

  //   window.removeEventListener('resize', window.throttledUpdateSmoothScrollOffset);
  //   window.addEventListener('resize', window.throttledUpdateSmoothScrollOffset);
  // }
})

import './foodservice/filtering';

import './foodservice/login';

// Fix transitioning between normal pages and stuff that's controlled by smoothstate
window.addEventListener('popstate', function(e) {
  if (e.state && e.state.id) {
    location.reload();
  }
});


dispatchPageContentLoaded()

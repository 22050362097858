import selfOrClosest from "../libs/self-or-closest"

class ActiveList {
  constructor(element) {
    this.element = element

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.handleListMouseEnter = this.handleListMouseEnter.bind(this)
    this.handleListMouseLeave = this.handleListMouseLeave.bind(this)
  }

  handleMouseEnter(event) {

    const currentItem = selfOrClosest(event.target, '.active-list__item')

    this.items.forEach((item) => {
      if (item === currentItem) {
        item.classList.add('active-list__item--active')
        return;
      }

      item.classList.remove('active-list__item--active')
    })
  }

  handleMouseLeave(event) {
    const currentItem = selfOrClosest(event.target, '.active-list__item')
    if (currentItem) {
      currentItem.classList.remove('active-list__item--active')
    }
  }

  handleListMouseEnter() {
    this.element.classList.add('active-list--active')
  }

  handleListMouseLeave() {

    if (!this.element.hasAttribute('data-active-list-always-active')) {
      this.element.classList.remove('active-list--active')
    }

    this.items.forEach((item) => {
      item.classList.remove('active-list__item--active')
    })
  }

  unload() {

    if (this.items) {
      this.items.forEach((item) => {
        item.removeEventListener('mouseenter', this.handleMouseEnter)
        item.removeEventListener('mouseleave', this.handleMouseLeave)
      })
    }

    this.element.removeEventListener('mouseenter', this.handleListMouseEnter);
    this.element.removeEventListener('mouseleave', this.handleListMouseLeave);
  }

  init() {

    if (this.element.hasAttribute('data-active-list-always-active')) {
      this.element.classList.add('active-list--active')
    }

    // Only include the elements that belong to the current active list
    // Allows for setting active-list__item to not necessarily need to be a direct child of the active list.
    this.items = [...this.element.querySelectorAll('.active-list__item')].filter((item) => {
      return (selfOrClosest(item, '.active-list') === this.element)
    })

    if (this.element.hasAttribute('data-active-list-active-item')) {
      const index = parseInt(this.element.dataset.activeListActiveItem) || 0
      if (this.items[index]) {
        this.items[index].classList.add('active-list__item--active')
      }
    }

    this.element.removeEventListener('mouseenter', this.handleListMouseEnter);
    this.element.removeEventListener('mouseleave', this.handleListMouseLeave);

    this.element.addEventListener('mouseenter', this.handleListMouseEnter);
    this.element.addEventListener('mouseleave', this.handleListMouseLeave);

    this.items.forEach((item) => {
      item.removeEventListener('mouseenter', this.handleMouseEnter)
      item.removeEventListener('mouseleave', this.handleMouseLeave)

      item.addEventListener('mouseenter', this.handleMouseEnter)
      item.addEventListener('mouseleave', this.handleMouseLeave)
    })
  }
}

export default ActiveList;

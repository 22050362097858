export const initToggles = function() {
  var toggleButtons = [...document.querySelectorAll('.js-toggle-sibling')];

  function nextElement(elem) {
    do {
      elem = elem.nextSibling;
    } while (elem.nodeType !== 1);

    return elem || null;
  }

  toggleButtons.map((button, index) => {
    button.addEventListener('click', function(e) {
      e.preventDefault();
      this.classList.toggle('is-active');
      nextElement(this).classList.toggle('is-active');
    });
  })

  return toggleButtons
}

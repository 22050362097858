const pageContentLoaded = new CustomEvent('PageContentLoaded')

export default function() {

  if (document.readyState === 'complete') {
    window.dispatchEvent(pageContentLoaded)
    return
  }

  window.addEventListener('DOMContentLoaded', () => {
    window.dispatchEvent(pageContentLoaded)
  });
}

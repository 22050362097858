const skipToMainContentBtn = document.querySelector('#skip-to-main-content')
const disableAutoplayingMediaBtn = document.querySelector('#disable-autoplaying-media')

if (disableAutoplayingMediaBtn) {

  const disableAutoplay = () => {
    [...document.querySelectorAll('video[autoplay]')].forEach((videoElem) => {
      videoElem.removeAttribute('autoplay')
      videoElem.pause()
    })
  }

  if (localStorage.getItem('disable_autoplay')) {
    window.addEventListener('load', () => {
      disableAutoplay()
    })
  }

  disableAutoplayingMediaBtn.addEventListener('click', () => {

    try {
      localStorage.setItem('disable_autoplay', true)
      disableAutoplay()
    } catch(e) {
    }
  })
}


  function init() {
    var emailOpener = document.querySelector('.js-form-email')

    if(!emailOpener) {
      return;
    }

    function emailOpenModal() {
      var email = document.getElementById('email-open-email').value;
      localStorage.setItem("emailDish", email);

      var emailModal = document.getElementById('email-modal-email');
      emailModal.value = localStorage.getItem("emailDish")

      window.modals['#newsletter-modal'].show()
    }

    console.log('modal form', emailOpener);

    emailOpener.addEventListener('submit', (event) => {
      event.preventDefault()
      emailOpenModal();
    })
  }

  export const initDishworthyModal = function() {
    init()
  }

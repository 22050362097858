var daysSince = require('mode-front-end/resources/assets/js/time/daysSince');
var isInViewport = require('mode-front-end/resources/assets/js/dom/isInViewport');


import { getTrackEvent } from '../analytics'
const trackGaEvent = getTrackEvent();

export const initSitePromoModal = function(modals) {

  const modalId = 'site-promo-modal';
  const modalSelector = `#${modalId}`;

  let scrollHandler;

  if (typeof modals[modalSelector] === 'undefined') {
    return;
  }

  const modal = modals[modalSelector]

  let config;
  try {
    config = JSON.parse(modal.element.dataset.promoConfig)
  } catch(e) {
    config = {}
    console.warn('Failed to parse promo config', e)
  }

  const showAfterElems = (config.showAfterSelectors || []).map((selector) => document.querySelector(selector)).filter((elem) => elem)
  const promoId = modal.element.dataset.promoId
  const promoEventCategory = modal.element.dataset.promoEventCategory
  const promoVisitedId = `${promoId}-visited`
  let delayTime = 1
  var modalTimeout = null

  const shouldPopupFromTimestamp = () => {
    // The last time the popup was viewed
    let lastPopupTimestamp = localStorage.getItem(promoId)
    let daysSincePopup = daysSince(lastPopupTimestamp)

    // If daysSincePopup is NaN, it's either never been set or has been removed.
    // Return true indicating the pipup should be displayed
    if (isNaN(daysSincePopup)) {
      return true;
    }

    // Return false if it's been zero days since the popup has been displayed.
    return !(lastPopupTimestamp && daysSincePopup <= 0);
  }

  // This should be set by the subject of the promotion. e.g:
  // For Journey Boldly, this would be set by the journey boldly landing page
  let promoVisited = localStorage.getItem(promoVisitedId)

  if (!window.location.hash.includes('promo-modal')) {
    // Promo has already been visited or the promo modal is already displayed
    if (!shouldPopupFromTimestamp() || promoVisited || modal.isActive()) {
      return;
    }
  }

  modal.element.addEventListener('modal.show', () => {
    trackGaEvent({
        'eventCategory': promoEventCategory,
        'eventAction': 'Popup Displayed',
        'eventValue': '1',
        'eventNonInteraction': true
    });
  })

  modal.element.addEventListener('modal.hide', () => {
    trackGaEvent({
        'eventCategory': promoEventCategory,
        'eventAction': 'Popup Closed',
        'eventNonInteraction': true
    });
  })

  const triggerModal = (force) => {

    if(document.body.classList.contains('modal-open')) {
      //console.log('another modal was triggered; hide promo model');
      return;
    }

    clearTimeout(modalTimeout)

    const blacklist = config.sectionBlacklist || [];

    // Prevent showing the modal of the page is blacklisted
    for (let i = 0; i < blacklist.length; i++) {
      if (window.location.href.match(new RegExp(blacklist[i], 'i'))) {
        console.log('popup matches blacklilst with ', blacklist[i])
        return;
      }
    }

    modalTimeout = setTimeout(function() {
        console.log(`triggered ${promoId} popup timeout`);
        modal.show();
        // Update the display timestamp for the promo
        localStorage.setItem(promoId, Date.now());

        // Remove the scroll handler once the promo has been displayed
        if (typeof scrollHandler !== 'undefined') {
          document.removeEventListener("scroll", scrollHandler);
        }
    }, delayTime * 1000);
  }

  if (config.disableScroll) {
    triggerModal();
  } else {
    scrollHandler = (e) => {
      // Return if the hero is still visible.
      const hasElementInViewport = showAfterElems.find((elem) => isInViewport(elem))
      if (hasElementInViewport || !shouldPopupFromTimestamp()) {
        return;
      }
      //console.log('scrollHandler triggered modal');
      triggerModal()
    }

    if (!showAfterElems) {
      document.addEventListener('DOMContentLoaded', function() {
        //console.log('dom content loaded - triggering modal');
        triggerModal()
        return;
      });
    }

    document.addEventListener("scroll", scrollHandler);
    //scrollHandler()
  }

  window.sitePromoModal = modal
}

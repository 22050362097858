class FullCta {
  constructor(element) {
    this.element = element

    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  handleMouseOver() {
    this.element.classList.add('hover')
  }

  handleMouseLeave() {
    this.element.classList.remove('hover')
  }

  unload() {
    if (this.link) {
      this.link.removeEventListener('mouseover', this.handleMouseOver)
      this.link.removeEventListener('mouseleave', this.handleMouseLeave)
    }
  }

  init() {
    this.link = this.element.querySelector('a')
    if (this.link) {
      this.link.addEventListener('mouseover', this.handleMouseOver)
      this.link.addEventListener('mouseleave', this.handleMouseLeave)
    }
  }
}

export default FullCta;
